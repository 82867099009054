import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Buffer } from 'buffer' // FOR BASE64 ENCODING / DECODING

class HTTPHelper {
    static instance = null;
    static createInstance() {
        var object = new HTTPHelper();
        return object;
    }

    static getInstance() {
        if (!HTTPHelper.instance) {
            HTTPHelper.instance = HTTPHelper.createInstance();
        }
        return HTTPHelper.instance;
    }

    constructor(){
    }

    async httpTokenPersonal(credentials,isDebug=false){
        try {
            var ENV_APIPATH = process.env.APIPATH;
            var urlRequest = ENV_APIPATH+"/token/personal";

            if(isDebug){
                urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {
                email: credentials.email,
                clave: credentials.clave,
            }
            return await axios.post(urlRequest, objRequest);

        } catch (error) {
            throw Error("[HTTPHelper] httpTokenPersonal()::ERROR >>> "+error);
        }
    }

    async httpTokenRequest(credentials,isDebug=false){
        try {
            var ENV_APIPATH = process.env.APIPATH;
            var urlRequest = ENV_APIPATH+"/token/request";

            if(isDebug){
                urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {
                email: credentials.email,
                clave: credentials.clave,
            }
            return await axios.post(urlRequest, objRequest);

        } catch (error) {
            throw Error("[HTTPHelper] httpTokenRequest()::ERROR >>> "+error);
        }
    }

    async httpTokenRefresh(refreshToken,isDebug=false){
        try {
            var ENV_APIPATH = process.env.APIPATH;
            var urlRefresh = ENV_APIPATH+"/token/refresh";

            if(isDebug){
                urlRefresh = urlRefresh+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRefresh = {
                refreshToken: refreshToken,
            }
            return await axios.post(urlRefresh, objRefresh);

        } catch (error) {
            throw Error("[HTTPHelper] httpTokenRefresh()::ERROR >>> "+error);
        }
    }

    async httpPublicPOST(url,body,isDebug=false,isBlob=false){
        try {
            // [IMPORTANT]
            // You cannot refresh a Refresh Token if the Refresh Token has expired or otherwise been revoked.
            // You must repeat the authentication flow to obtain a new Refresh Token.

            var actualPublicAccessToken = store.getters['authPublicStore/getAccessToken'];
            //console.log(">>> ACTUAL publicAccessToken = "+actualPublicAccessToken);

            if(actualPublicAccessToken==""){
                var ENV_APIPATH = process.env.APIPATH;
                var urlRequest = ENV_APIPATH+"/token/personal";

                if(isDebug){
                    urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                }

                var encryptedEmail = Buffer.from(process.env.APITOKEN_USER).toString('base64');
                var encryptedPassword = Buffer.from(process.env.APITOKEN_PASSWORD).toString('base64');

                var objRequest = {
                    email: encryptedEmail,
                    clave: encryptedPassword,
                }

                var result = await axios.post(urlRequest, objRequest);
                var responseStatus = result.status;
                var responseMessage = result.message;
                var responseData = result.data.response;

                switch(responseStatus) {
                    case 200:
                        var newPublicAccessToken = responseData.access_token;
                        store.dispatch('authPublicStore/setAccessToken',newPublicAccessToken);
                        //console.log(">>> NEW publicAccessToken = "+newPublicAccessToken);
                        break;

                    default:
                        throw Error("Error en Request Token - "+responseMessage);
                }
            }

            const axiosApiInstance = axios.create();

            axiosApiInstance.interceptors.request.use(
                config => {
                    var accessToken = store.getters['authPublicStore/getAccessToken'];
                    //console.log(">>> accessToken = "+accessToken);
                    config.headers['Authorization'] = 'Bearer ' + accessToken;

                    return config;
                },
                error => {
                    Promise.reject(error)
                }
            );

            axiosApiInstance.interceptors.response.use((response) => {
                return response
            }, async function (error) {
                const originalRequest = error.config;
                var errorData = error.response.data;
                var errorMessage = errorData.message;

                if (error.response.status !== 401) {
                    // Cuando es ERROR de BACKEND (500)
                    if(error.response.status == 500){
                        return Promise.reject(errorMessage);
                    }

                    // Cuando es cualquier otro ERROR (!=401)
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    var ENV_APIPATH = process.env.APIPATH;
                    var urlRequest = ENV_APIPATH+"/token/personal";

                    if(isDebug){
                        urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                    }

                    var encryptedEmail = Buffer.from(process.env.APITOKEN_USER).toString('base64');
                    var encryptedPassword = Buffer.from(process.env.APITOKEN_PASSWORD).toString('base64');

                    var objRequest = {
                        email: encryptedEmail,
                        clave: encryptedPassword,
                    }

                    var result = await axios.post(urlRequest, objRequest);
                    var responseStatus = result.status;
                    var responseMessage = result.message;
                    var responseData = result.data.response;

                    switch(responseStatus) {
                        case 200:
                            // 1. Se obtuvo CON ÉXITO el AccessToken
                            var newPublicAccessToken = responseData.access_token;

                            // 2. Almacenamos el NUEVO AccessToken:
                            store.dispatch('authPublicStore/setAccessToken',newPublicAccessToken);
                            //console.log(">>> NEW publicAccessToken = "+newPublicAccessToken);

                            // 3. Con el nuevo AccessToken válido realizamos de nuevo la llamada al API
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newPublicAccessToken;
                            return axiosApiInstance(originalRequest);
                            break;

                        default:
                            throw Error("Error en Personal Token - "+responseMessage);
                    }
                }

                return Promise.reject(errorMessage);

            });

            if(isBlob){
                return axiosApiInstance({
                    url: url,
                    data: body,
                    method: 'POST',
                    responseType: 'blob',
                });
            }

            return axiosApiInstance.post(url,body);

        } catch (error) {
            throw Error("[HTTPHelper] httpPublicPOST()::ERROR >>> "+error);
        }
    }

    async httpSessionPOST(url,body,isDebug=false,isBlob=false){
        try {
            // [IMPORTANT]
            // You cannot refresh a Refresh Token if the Refresh Token has expired or otherwise been revoked.
            // You must repeat the authentication flow to obtain a new Refresh Token.

            var actualSessionAccessToken = store.getters['authSessionStore/getAccessToken'];
            var actualSessionRefreshToken = store.getters['authSessionStore/getRefreshToken'];

            //console.log("[httpSessionPOST]  ACTUAL sessionAccessToken = ",actualSessionAccessToken);
            //console.log("[httpSessionPOST]  ACTUAL sessionRefreshToken = ",actualSessionRefreshToken);

            if(actualSessionAccessToken=="" || actualSessionRefreshToken==""){
                // CASO: Uno de los tokens ha sido COMPROMETIDO.
                //       Hacemos LOGOUT para que el usuario haga LOGIN y se creen nuevos tokens
                var errorMessage = "LOGOUT porque uno de los TOKENS ha sido comprometido";
                //console.log("[httpSessionPOST] errorMessage",errorMessage);

                store.dispatch('authSessionStore/logOut');

                var nextRoute = 'session_index';
                router.replace({ name: nextRoute }, () => {
                });

                return Promise.reject(errorMessage);
            }

            const axiosApiInstance = axios.create();

            axiosApiInstance.interceptors.request.use(
                config => {
                    var accessToken = store.getters['authSessionStore/getAccessToken'];
                    //console.log("[httpSessionPOST] sessionAccessToken TO BE USED = ",accessToken);

                    config.headers['Authorization'] = 'Bearer ' + accessToken;

                    return config;
                },
                error => {
                    Promise.reject(error)
                }
            );

            axiosApiInstance.interceptors.response.use((response) => {
                return response
            }, async function (error) {
                const originalRequest = error.config;
                var errorData = error.response.data;
                var errorMessage = errorData.message;

                if (error.response.status !== 401) {
                    // Cuando es ERROR de BACKEND (500)
                    if(error.response.status == 500){
                        return Promise.reject(errorMessage);
                    }

                    // Cuando es cualquier otro ERROR (!=401)
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    var ENV_APIPATH = process.env.APIPATH;
                    var urlRequest = ENV_APIPATH+"/token/refresh";

                    if(isDebug){
                        urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                    }

                    var sessionRefreshToken = store.getters['authSessionStore/getRefreshToken'];

                    var objRequest = {
                        refreshToken: sessionRefreshToken,
                    }

                    var result = await axios.post(urlRequest, objRequest);
                    var responseStatus = result.status;
                    var responseMessage = result.message;
                    var responseData = result.data.response;

                    switch(responseStatus) {
                        case 200:
                            // 1. Se obtuvo CON ÉXITO el AccessToken/RefreshToken
                            var newSessionRefreshToken = responseData.refresh_token;
                            var newSessionAccessToken = responseData.access_token;

                            //console.log("[httpSessionPOST] NEW newSessionRefreshToken = ",newSessionRefreshToken);
                            //console.log("[httpSessionPOST] NEW sessionAccessToken = ",newSessionAccessToken);

                            // 2. Almacenamos el NUEVO AccessToken:
                            store.dispatch('authSessionStore/setRefreshToken',newSessionRefreshToken);
                            store.dispatch('authSessionStore/setAccessToken',newSessionAccessToken);

                            // 3. Con el nuevo AccessToken válido realizamos de nuevo la llamada al API
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newSessionAccessToken;
                            return axiosApiInstance(originalRequest);
                            break;

                        default:
                            // CASO: Los tokens han EXPIRADO o ya NO SON VÁLIDOS.
                            //       Hacemos LOGOUT para que el usuario haga LOGIN y se creen nuevos tokens
                            var errorMessage = "LOGOUT por superar tiempo de inactividad";
                            //console.log("[httpSessionPOST] errorMessage",errorMessage);

                            store.dispatch('authSessionStore/logOut');

                            var nextRoute = 'session_index';
                            router.replace({ name: nextRoute }, () => {
                            });

                            return Promise.reject(errorMessage);
                    }
                }

                return Promise.reject(errorMessage);

            });

            if(isBlob){
                return axiosApiInstance({
                    url: url,
                    data: body,
                    method: 'POST',
                    responseType: 'blob',
                });
            }

            return axiosApiInstance.post(url,body);

        } catch (error) {
            throw Error("[HTTPHelper] httpSessionPOST()::ERROR >>> "+error);
        }
    }

    async httpPOST(url,body,accessType='PUBLIC',isDebug=false){
        if(isDebug){
            url = url+"?XDEBUG_SESSION_START=phpstorm-xdebug";
        }

        switch(accessType) {
            case 'PUBLIC':
                return await this.httpPublicPOST(url,body,isDebug);

            case 'SESSION':
                return await this.httpSessionPOST(url,body,isDebug);

            default:
                throw Error("[HTTPHelper] httpPOST()::ERROR >>> Invalid ACCESSTYPE = '"+accessType+"'");
        }
    }

    async httpPOSTBlob(url,body,accessType='PUBLIC',isDebug=false){
        if(isDebug){
            url = url+"?XDEBUG_SESSION_START=phpstorm-xdebug";
        }

        switch(accessType) {
            case 'PUBLIC':
                return await this.httpPublicPOST(url,body,isDebug,true);

            case 'SESSION':
                return await this.httpSessionPOST(url,body,isDebug,true);

            default:
                throw Error("[HTTPHelper] httpPOSTBlob()::ERROR >>> Invalid ACCESSTYPE = '"+accessType+"'");
        }
    }
}

const instance = HTTPHelper.getInstance();
export default instance;